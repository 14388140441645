import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
//Images
import Seo from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";

const PrivateParking = () => {
  return (
    <Layout>
      <Seo
        title="Inicio - Parking - BIKESAFE"
        description="Cómo optimizar el espacio para bicicletas en grandes desarrollos. Bike Safe ofrece soluciones especializadas de aparcabicicletas y racks para proyectos comerciales, instalaciones públicas y grandes complejos."
      />
      <Container>
        <Row>
          <Col sm="12">
            <h1 className="mt-5 mb-5 text-center">Inicio - Parking</h1>
          </Col>
        </Row>
        <Row>
          <Col sm="12" className="text-center">
            <p className="flow-text">
              Optimice el espacio de sus instalaciones con nuestras soluciones
              especializadas en aparcabicicletas. En Bike Safe, diseñamos y
              suministramos racks de alta calidad para proyectos comerciales,
              estacionamientos, centros urbanos y grandes complejos,
              garantizando la seguridad y comodidad de los ciclistas mientras
              aprovecha al máximo el espacio disponible.
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm="12" className="text-center">
            <StaticImage
              placeholder="blurred"
              src="../images/sheffield.jpg"
              className="shadow mt-5 mb-5 fluid rounded"
            />
            <p>
              <em className="flow-text">
                "Quedamos encantados con el amable y eficaz servicio que nos
                prestó Bike Safe. Instalaron un aparcabicicletas Sheffield en
                nuestras instalaciones a los pocos días de ponernos en contacto
                con ellos. El aparcabicicletas tiene un aspecto magnífico y
                ofrece un lugar cómodo para guardar las bicicletas de forma
                segura sin obstaculizar el espacio."
                <strong>
                  {" "}
                  Emlyn Lamburn, gerente de instalaciones, Londres
                </strong>
                .
              </em>
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default PrivateParking;
